@import 'styles/config';

.container {
    min-height: 100vh;
    padding: 0 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.background_fallback {
    border-radius: 0;
    position: absolute;
    top: 0px;
    right: 0px;
    height: 60%;
    min-width: 1440px;
    width: 100%;
    mask-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 1),
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0)
    );
    z-index: -2;
}

.background {
    border-radius: 0;
    position: absolute;
    top: 0px;
    right: 0px;
    object-fit: fill;
    height: 60%;
    min-width: 2000px;
    width: 100%;
    mask-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 1),
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0)
    );
    z-index: -1;

    @media (max-width: $max-mobile) {
        display: none;
    }
}

.header {
    row-gap: scale-to-em(32);

    &__item {
        @include grid-item(8, 2);
        @media (max-width: $max-mobile) {
            @include grid-item();
        }
        margin: 0px;
    }

    &__buttons {
        @include grid-item();

        display: flex;
        justify-self: center;
        gap: scale-to-em(70);
        @media (max-width: $max-mobile) {
            flex-direction: column;
            gap: scale-to-em(30);
            align-items: center;
        }
    }
}

.br2 {
    @media (min-width: $min-desktop) {
        display: none;
    }
}

.home {
    section {
        @include section(
            $padding-top: $section-padding * 2,
            $padding-bottom: $section-padding * 2,
            $padding-top-mobile: $section-padding-mobile,
            $padding-bottom-mobile: $section-padding-mobile
        );

        & + & {
            padding-top: 0;
        }
    }
}

.card {
    @include grid-item();
}

.hverjuImage {
    @include grid-item(6, 0);
    object-fit: cover;
    width: 100%;
    max-height: 600px;
    aspect-ratio: 1/1;

    @media (max-width: $max-tablet) {
        @include grid-item();
    }
}

.gjof {
    @include grid-item(6, 6);

    @media (max-width: $max-tablet) {
        @include grid-item();
    }
}

.hverjuText {
    @include grid-item(4, 7);
    margin-top: scale-to-em(50);

    @media (max-width: $max-desktop) {
        @include grid-item(5, 7);
        margin-top: scale-to-em(0);
    }

    @media (max-width: $max-tablet) {
        @include grid-item();
        margin-top: scale-to-em(25);
    }

    button {
        margin-top: scale-to-em(40);
    }
}

.gjofText {
    @include grid-item(4, 1);
    grid-row: 1;

    @media (max-width: $max-desktop) {
        @include grid-item(5, 0);
    }

    @media (max-width: $max-tablet) {
        grid-row: 2;

        @include grid-item();
    }
}

.headerSection {
    padding-bottom: 0px !important;
    padding-top: 90px !important;
}

.first {
    // padding-bottom: 30px !important;
    padding-top: 90px !important;
    @media (max-width: $max-tablet) {
        // padding-bottom: 15px !important;
        padding-top: 30px !important;
    }
}

.video {
    padding: 0px !important;
}

.noTopPadd {
    padding-top: 0px !important;
}
.noBotPadd {
    padding-bottom: 0px !important;
}

.noPadd {
    padding: 0px !important;
}

.grid {
    @include grid(3, $grid-row-gap: 4em);
    @media (max-width: $max-desktop) {
        @include grid(2, $grid-row-gap: 0em);
        & > div:nth-child(3) {
            @include grid-item(2, 0);
            grid-row: 2;
        }
    }
    @media (max-width: $max-tablet) {
        @include grid(1, $grid-row-gap: 0em);
        & > div:nth-child(1) {
            grid-row: 1;
        }
        & > div:nth-child(2) {
            grid-row: 2;
        }
        & > div:nth-child(3) {
            grid-row: 3;
        }
    }
}

// Liggur niðri styles

.logo {
    margin-top: 10em;
    margin-bottom: 1em;
    width: 300px;
    height: 100px;
    @include grid-item();
    justify-self: center;
}
.text {
    @include grid-item();
    justify-self: center;
    margin-bottom: 0.2em;
    text-align: center;
}

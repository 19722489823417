// inní component
@import '/styles/config.scss';

.header {
    @include grid-item();
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: scale-to-em(32);
    margin-bottom: scale-to-em(32);
    // margin-top: scale-to-em(32);

    .buttons {
        display: flex;
        gap: scale-to-em(6);

        button {
            gap: 0px;
        }
    }
}

.grid {
    @include grid-item();
}

.viewMore {
    @include grid-item();
    justify-self: flex-end;
    margin-top: scale-to-em(20);
}

.icons {
    display: flex;
    gap: 15px;
}

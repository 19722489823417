@import '/styles/config.scss';

.container {
    @include grid-item();
    position: relative;

    & > .motion {
        position: absolute;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        padding-bottom: 75px;
    }

    &:after {
        @include grid-item();
        grid-row: 1;
        content: '';
        display: block;
        height: 700px;
        @media (max-width: $max-desktop) {
            height: 950px;
        }
        @media (max-width: $max-mobile) {
            height: 660px;
        }
        width: 100%;
    }
}

.card {
    padding: 0px;
    max-height: 100%;
    align-self: center;
    justify-self: center;
    display: flex;
    align-content: center;
    margin: 0px;

    img {
        pointer-events: none;
        max-height: 500px;
        @media (max-width: $max-desktop) {
            max-height: 450px;
        }
        @media (max-width: $max-mobile) {
            max-height: 300px;
        }
    }
}

// Line with buttons
.flex {
    @include grid-item(10, 1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-self: flex-end;
    z-index: 1;

    .bg {
        height: 2px;
        width: 420px;
        background-color: $soft-green;
        position: relative;
        .timer {
            transform-origin: 0 0;
            height: 100%;
            width: 100%;
            background-color: $soft-navy;
        }
    }

    @media (max-width: $max-desktop) {
        @include grid-item();

        flex-direction: column;
        gap: scale-to-em(40);

        .bg {
            width: 100%;
        }
    }
}

.header {
    position: absolute;
    left: 50%; /* position the left edge of the element at the middle of the parent */
    width: 100%;
    transform: translate(-50%, 0%);
    @media (max-width: $max-desktop) {
        margin-top: 15px;
    }
}

@import '/styles/config.scss';

.mailLayout {
    @include grid();
    align-items: center;
    position: relative;
    min-height: 400px;
    @media (max-width: $max-tablet) {
        @include grid-item();
        // display: flex;
        // align-items: center;
        // fle
    }
}

.image {
    @include grid-item(6, 1);
    // grid-column: 1 / span 6;
    grid-row: 1;
    @media (max-width: $max-desktop) {
        // grid-column: 0 / span 8;
        @include grid-item(6, 0);
        // max-width: scale-to-em(500);
    }
    @media (max-width: $max-tablet) {
        @include grid-item(10, 1);
    }
    @media (max-width: $max-mobile) {
        @include grid-item();
    }
}

.formContainer {
    @include grid-item(6, 5);
    grid-row: 1;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: scale-to-em(8);
    padding: scale-to-em(40) scale-to-em(48);
    @media (max-width: $max-desktop) {
        @include grid-item(8, 4);
        position: absolute !important;
        padding: scale-to-em(32) scale-to-em(32) !important;
    }
    @media (max-width: $max-tablet) {
        @include grid-item(10, 1);
        padding: scale-to-em(32) scale-to-em(24) !important;
        margin: 0 scale-to-em(16);
        position: static !important;
        margin-top: scale-to-em(-100);
        grid-row: 2;
        align-self: flex-end;
    }
    @media (max-width: $max-mobile) {
        @include grid-item();
    }
}

// .desktopTitle {
//     display: block;

// }

// .tabletTitle {
//     display: none;
// }

.form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    & > div {
        width: 100%;
    }

    @media (max-width: $max-tablet) {
        grid-template-columns: 1fr;
        gap: 0;
    }

    @media (max-width: $max-mobile) {
        button {
            width: 100%;
        }
    }
}

.completeState {
    display: flex;
    flex-direction: column;
    p {
        font-weight: 500;
    }
}

.errorState {
    display: flex;
    flex-direction: column;
    p {
        font-weight: 500;
        color: $color-error;
    }
}

.button {
    justify-self: flex-end;
    align-self: flex-end;
}

.refreshBtn {
    align-self: center;
}

.loader {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    position: relative;
    margin: 0 auto;
    margin-top: scale-to-em(35);
    margin-bottom: scale-to-em(50);
    overflow: hidden;
    -webkit-animation: spin 2s linear infinite;
    -moz-animation: spin 2s linear infinite;
    -o-animation: spin 2s linear infinite;
    -ms-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}
.loaderInside:hover {
    -webkit-animation-play-state: paused;
    -moz-animation-play-state: paused;
    -o-animation-play-state: paused;
    -ms-animation-play-state: paused;
    -animation-play-state: paused;
}
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -transform: rotate(360deg);
    }
}

.loaderInside {
    width: 100%;
    height: 50%;
    position: absolute;
    margin-top: 50%;
    background: linear-gradient(90deg, white, #cfe6e5, #bfc2c1);
}
.loaderInside:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    margin-top: -50%;
    background: linear-gradient(90deg, #ff5b5b, #bfc2c1);
}
.loaderInside:after {
    content: '';
    width: 70%;
    height: 140%;
    position: absolute;
    margin-top: -35%;
    margin-left: 15%;
    background: white;
    border-radius: 50%;
}
